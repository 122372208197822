import React from 'react';

import { isTag } from 'domhandler';
import HTMLReactParser, { domToReact } from 'html-react-parser';
import { Link } from 'mp-ui-components';

export const baseHtmlToReact = (htmlMsg: string) =>
  HTMLReactParser(htmlMsg, {
    replace: (node) => {
      if (!isTag(node)) {
        return;
      }

      if (node.tagName === 'a') {
        return (
          <Link target="_self" href={node.attribs.href}>
            {domToReact(node.children)}
          </Link>
        );
      }
    },
  });
