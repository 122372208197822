import { UserRoles } from '@lmru-bpms-ved/core';

import { i18n } from '@src/shared/i18n';

export enum RootRoute {
  sign = '/sign',
  login = '/login',
  logout = '/logout',
  helpAndSupport = '/support',
  orders = '/orders',
  tasks = '/tasks',
  shipments = '/shipments',
  shipmentsTracking = '/shipmentsTracking',
  directModeDocs = '/directMode',
  reports = '/reports',
  forwarderReports = '/forwarderReports',
  brokerReports = '/brokerReports',
  idc = '/idc',
}

type RouteConfig = {
  navLabel?: string;
  roleAccessWhitelist?: UserRoles[];
  defaultForRole?: UserRoles[];
};

export const rootRoutesConfig: Record<RootRoute, RouteConfig> = {
  [RootRoute.sign]: {},

  [RootRoute.login]: {},

  [RootRoute.logout]: {},

  [RootRoute.orders]: {
    navLabel: 'Orders',
    roleAccessWhitelist: [
      UserRoles.AdeoSupplier,
      UserRoles.ImportSpecialist,
      UserRoles.CustomsDocs,
      UserRoles.CustomsGroupSpecialist,
      UserRoles.TransportGroupSpecialist,
      UserRoles.CertificationSpecialist,
      UserRoles.CertBody,
      UserRoles.ApprovisionSpecialist,
      UserRoles.AdeoTransport,
      UserRoles.SampleCoordinator,
      UserRoles.AdeoVietnam,
    ],
    defaultForRole: [UserRoles.SampleCoordinator],
  },

  [RootRoute.tasks]: {
    navLabel: 'Tasks',
    roleAccessWhitelist: [
      UserRoles.AdeoSupplier,
      UserRoles.ImportSpecialist,
      UserRoles.CustomsDocs,
      UserRoles.CertificationSpecialist,
      UserRoles.CertBody,
      UserRoles.ApprovisionSpecialist,
      UserRoles.AdeoTransport,
      UserRoles.AdeoVietnam,
    ],
    defaultForRole: [
      UserRoles.AdeoSupplier,
      UserRoles.ImportSpecialist,
      UserRoles.CustomsDocs,
      UserRoles.CertBody,
      UserRoles.CertificationSpecialist,
      UserRoles.ApprovisionSpecialist,
      UserRoles.AdeoVietnam,
    ],
  },

  [RootRoute.shipments]: {
    navLabel: 'Shipping docs',
    roleAccessWhitelist: [
      UserRoles.AdeoSupplier,
      UserRoles.ImportSpecialist,
      UserRoles.CustomsDocs,
      UserRoles.CustomsGroupSpecialist,
      UserRoles.TransportGroupSpecialist,
      UserRoles.ApprovisionSpecialist,
      UserRoles.AdeoTransport,
      UserRoles.SampleCoordinator,
      UserRoles.AdeoVietnam,
    ],
    defaultForRole: [
      UserRoles.CustomsGroupSpecialist,
      UserRoles.TransportGroupSpecialist,
      UserRoles.AdeoTransport,
    ],
  },

  [RootRoute.directModeDocs]: {
    navLabel: 'Direct mode',
    roleAccessWhitelist: [UserRoles.ImportSpecialist, UserRoles.SampleCoordinator],
  },

  [RootRoute.idc]: {
    navLabel: i18n.idc.navLabel,
    roleAccessWhitelist: [
      UserRoles.ImportSpecialist,
      UserRoles.SampleCoordinator,
      UserRoles.CertificationSpecialist,
      UserRoles.CustomsGroupSpecialist,
    ],
  },

  [RootRoute.shipmentsTracking]: {
    navLabel: 'Shipments tracking',
    roleAccessWhitelist: [UserRoles.TransportGroupSpecialist],
  },

  [RootRoute.reports]: {
    navLabel: 'Reports',
    roleAccessWhitelist: [
      UserRoles.AdeoSupplier,
      UserRoles.ImportSpecialist,
      UserRoles.CustomsDocs,
      UserRoles.CustomsGroupSpecialist,
      UserRoles.TransportGroupSpecialist,
      UserRoles.CertificationSpecialist,
      UserRoles.CertBody,
      UserRoles.ApprovisionSpecialist,
      UserRoles.AdeoTransport,
      UserRoles.SampleCoordinator,
    ],
  },

  [RootRoute.forwarderReports]: {
    navLabel: 'Reports',
    roleAccessWhitelist: [UserRoles.Forwarder],
    defaultForRole: [UserRoles.Forwarder],
  },

  [RootRoute.brokerReports]: {
    navLabel: 'Reports',
    roleAccessWhitelist: [UserRoles.CustomsBroker],
    defaultForRole: [UserRoles.CustomsBroker],
  },

  [RootRoute.helpAndSupport]: {
    navLabel: 'Help & Support',
  },
};
