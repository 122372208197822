import React from 'react';

import dayjs from 'dayjs';
import { Button, Input } from 'mp-ui-components';
import { useQuery } from 'react-query';

import { AuthService } from '@src/shared/services/auth';
import { defaultQueryOptions } from '@src/shared/services/query';
import { RoutingService } from '@src/shared/services/routing';

import { externalAuth } from '../../../../shared/services/httpClient';

import styles from './index.css';

let invalidCredsAttempts = 0;

const getAuthErrorMessage = ({ error: errorType, lockout, endLockoutDate }) => {
  if (lockout) {
    const date = dayjs(endLockoutDate);

    return endLockoutDate && date.isValid()
      ? `Ohh too many attempts.\nPlease try again after ${date.add(1, 'm').format('HH:mm')}.`
      : 'Account is not found. Please use Help&Support page to request your credentials.';
  }

  if (errorType === 'invalid_grant') {
    const invalidCredsMsg = `Email or password is incorrect.${
      invalidCredsAttempts > 0
        ? `\nIf you don’t remember it, please use Help&Support page to request your credentials.`
        : ''
    }`;

    invalidCredsAttempts++;

    return invalidCredsMsg;
  }

  return 'Technical error. Please try again later.';
};

export const SignInPage = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [failureText, setFailureText] = React.useState('');

  const {
    data: ssoUrls,
    isLoading,
    isLoadingError,
  } = useQuery({
    queryKey: AuthService.ssoUrlQueryDef.getQueryKey(),
    queryFn: AuthService.ssoUrlQueryDef.queryFn,
    onError: () => {
      setFailureText('Technical error. Please refresh the page and try again later.');
    },
    ...defaultQueryOptions,
  });

  const isSsoReady = !isLoading && !isLoadingError;

  const submitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    externalAuth({
      username,
      password,
    })
      .then(() => {
        const defaultRoute = RoutingService.getDefaultRouteForRole(
          AuthService.getUserIdToken()?.vedRole,
        );

        window.location.href = defaultRoute;
      })
      .catch((error) => {
        const errorData = error?.response?.data ?? {};

        setFailureText(getAuthErrorMessage(errorData));
      });
  };

  return (
    <div>
      <div className={styles.container}>
        <span className={styles.formLabel}>Login form for external users</span>
        <form className={styles.form} onSubmit={submitHandler}>
          <div className={styles.inputLabel}>Email</div>

          <Input
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            value={username}
            placeholder="username@mail.us"
            className={styles.input}
          />
          <div className={styles.inputLabel}>Password</div>
          <Input
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            className={styles.input}
            type="password"
          />
          <div className={styles.formControls}>
            <Button>LOG IN</Button>
            {isSsoReady && (
              <a href={ssoUrls?.loginUrl} className={styles.employLink}>
                {`I'm an employee of Le Monlid`}
              </a>
            )}
          </div>
        </form>
        {failureText && <div className={styles.errorBlock}>{failureText}</div>}
      </div>
    </div>
  );
};
