import React from 'react';

import qs from 'qs';

import { AuthService } from '@src/shared/services/auth';

import { CodeValidationPage } from './CodeValidation';

export const RedirectAuthPage = () => {
  const location = qs.parse(window.location.search.slice(1));

  if (location?.code !== undefined) {
    const code = String(location.code);

    return <CodeValidationPage code={code} />;
  }

  AuthService.logout();

  return null;
};
