import React from 'react';

import { styled } from 'linaria/lib/react';
import { Divider } from 'mp-ui-components';

import { baseHtmlToReact } from '../../../../../../../../../shared/utils/html/baseHtmlToReact';
import { FileStatus as FileStatusEnum } from '../../../../../../../enums/enums';
import {
  getDateAsFormattedText,
  getStatusText,
} from '../../../../../../../pages/ShipmentsTrackingPage/domain/trackingFile/trackingFileDto';

import styles from './styles.css';

const titleColorByStatus = {
  [FileStatusEnum.HandleWithoutErrors]: '#333333',
  [FileStatusEnum.Idle]: '#FF8100',
  [FileStatusEnum.Processing]: '#FF8100',
  default: '#E30613',
};

const Title = styled.div<{ status: string }>`
  color: ${({ status }) => titleColorByStatus[status] ?? titleColorByStatus.default};
`;

type Props = {
  lastSuccessfulUpdated: string;
  status: string;
  convertedErrors: Array<string>;
  updated: string;
  placeholder: any;
};

export const FileStatus = ({
  status,
  convertedErrors,
  updated,
  lastSuccessfulUpdated,
  placeholder,
}: Props) => {
  const showLastUpdateDate =
    updated &&
    ![FileStatusEnum.HandleWithoutErrors, FileStatusEnum.Processing, FileStatusEnum.Idle].includes(
      status,
    );

  const isDividerShown = lastSuccessfulUpdated || (placeholder && status);

  return (
    <div className={styles.statusPart}>
      {status && (
        <Title status={status}>
          <div className={styles.statusTitle}>
            {getStatusText(status)}
            {showLastUpdateDate && (
              <>
                : <b>{getDateAsFormattedText(updated)}</b>
              </>
            )}
          </div>
        </Title>
      )}

      {convertedErrors?.length > 0 ? (
        <div className={styles.statusTitle}>
          {convertedErrors?.map((errorMsg, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.errorString}>
              {baseHtmlToReact(errorMsg)}
            </div>
          ))}
        </div>
      ) : null}

      {isDividerShown && <Divider style={{ margin: '10px 0 8px' }} />}

      {lastSuccessfulUpdated && (
        <div>
          Last successful parsing: <b>{getDateAsFormattedText(lastSuccessfulUpdated)}</b>
        </div>
      )}

      <div className={styles.placeholder}>{placeholder}</div>
    </div>
  );
};
