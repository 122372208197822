import React from 'react';

import { baseHtmlToReact } from '@src/shared/utils/html/baseHtmlToReact';

import { ParsingDetail } from './types';

export const getTextNode = (details: ParsingDetail[]) => {
  const htmlText = details.reduce((acc, item, ind) => {
    const messages = Array.isArray(item.info) ? item.info : [item.info];

    const messagesHtml = messages.join('<br>');

    if (ind === 0) {
      return `${messagesHtml}<br>`;
    }

    return `${acc}<br><span class="tableDetailsItemTitle">${item.title}</span><br>${messagesHtml}<br>`;
  }, '');

  return baseHtmlToReact(htmlText);
};
